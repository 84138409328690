import React from 'react';
import './Setup.css'; // Ensure CSS is imported

function Setup() {
  // Function to scroll to the bottom of the page
  const scrollToBottom = (pixels) => {
    window.scrollBy({
      top: pixels, // Number of pixels to scroll by
      behavior: 'smooth' // Smooth scroll
    });
  };
  return (
    <div className="setup-container">
      <div className="setup-instructions">
        <h1>How To Set Up?</h1>
        <ol>

          <li>Email us & Share your physical menu or a list of items</li>
          <li>We'll create your website within 1-2 days</li>
          <li>Once completed, we'll ship your QR code stickers, ready to be placed</li>
          <p className ="as">*After subscribing, you will receive QR code stickers.</p>
          <p className ="as">*If you choose to cancel your subscription, the website will automatically be deactivated.</p>
        </ol>
      </div>
      <div className="setup-plan">
        <div className="plan-card">
          <h2 className="plan-title">MONTHLY PLAN</h2>
          <div className="plan-title2">
            <p>$19.99/month,  Cancel Anytime</p>
    

          </div>
          
          <ul>
            <li className="plan-title3">Feutures:</li>
            <li className="plan-title4">• Digital QR Menu</li>
            <li className="plan-title4">• Physical QR Code Stickers</li>
            <li className="plan-title4">• Menu Updates on Request</li>
          </ul>
          <button  onClick={() => scrollToBottom(10000)}>Contact Us</button>
        </div>
      </div>
    </div>
  );
}

export default Setup;
