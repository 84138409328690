
import React from 'react';
import './PartnerRestaurants.css'; // Ensure CSS is properly imported
import logoA from '../images/logoA.png'; // Adjust the path as necessary
import logoD1 from '../images/logoD1.png'; // Adjust the path as necessary
import logoD2 from '../images/logoD2.png'; // Adjust the path as necessary
import logoB from '../images/logoB.png'; // Adjust the path as necessary


function PartnerRestaurants() {
    return (
        <div className="partner-restaurants">
            <h2>WHO LOVES SCANTABLEQR?</h2>
            <div className="scrolling-wrapper">
                {[...Array(10)].map((_, i) => (
                    <React.Fragment key={i}>
                        <img src={logoA} alt="Restaurant Logo" />
                        <img src={logoD1} alt="Restaurant Logo" />
                        <img src={logoD2} alt="Restaurant Logo" />
                        <img src={logoB} alt="Restaurant Logo" />

                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default PartnerRestaurants;
